import "./App.css";
import RenderOnAnonymous from "./KC-Auth/RenderOnAnonymous";
import RenderOnAuthenticated from "./KC-Auth/RenderOnAuthenticated";
import Navbar from "./Components/Navbar";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { Provider } from "react-redux";
import store from "./Redux/Store/store";
import Home from "./Components/Home.jsx";
import Dashboard from "./Components/Dashboard.jsx"
import Support from "./Components/Support.jsx"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import JobPosting from "./Components/JobPosting.jsx";
import ReportingManagerMaster from "./Components/ReportingManagerMaster.jsx";
import HiringManagerMaster from "./Components/HiringManagerMaster.jsx";
import SkillsetMaster from "./Components/SkillsetMaster.jsx";
import JobPositionMaster from "./Components/JobPositionMaster.jsx";
import RecruitmentManagerMaster from "./Components/RecruitmentManagerMaster.jsx";
import VendorMaster from "./Components/VendorMaster.jsx";
import StatusMaster from "./Components/StatusMaster.jsx";
import ClientMaster from "./Components/ClientMaster.jsx";
import DepartmentMaster from "./Components/DepartmentMaster.jsx";
import EmployeeMaster from "./Components/EmployeeMaster.jsx";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
   
       <BrowserRouter>
        <ReactNotifications />
        <Navbar />
        <RenderOnAuthenticated> 
          <Routes>
         <Route exact path="/" element={<Home />} />
         <Route exact path="/jobposting" element={<JobPosting/>}/>
         <Route exact path="/dashboard" element={<Dashboard/>}/>
         <Route exact path="/support" element={<Support/>}/>
         <Route exact path="/reportingmanagermaster" element={<ReportingManagerMaster/>}/>
         <Route exact path="/hiringmanagermaster" element={<HiringManagerMaster/>}/>
         <Route exact path="/employeemaster" element={<EmployeeMaster/>}/>
         <Route exact path="/skillsetmaster" element={<SkillsetMaster/>}/>
         <Route exact path="/jobpositionmaster" element={<JobPositionMaster/>}/>
         <Route exact path="/recruitmentmanagermaster" element={<RecruitmentManagerMaster/>}/>
         <Route exact path="/vendormaster" element={<VendorMaster/>}/>
         <Route exact path="/statusmaster" element={<StatusMaster/>}/>
         <Route exact path="/clientmaster" element={<ClientMaster/>}/>
         <Route exact path="/departmentmaster" element={<DepartmentMaster/>}/>
         <Route exact path="/departmentmaster" element={<DepartmentMaster/>}/>
          </Routes> 
        </RenderOnAuthenticated>
        <RenderOnAnonymous>
          <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/dashboard" element={<Dashboard/>}/> */}
          {/* <Route exact path="/reportingmanagermaster" element={<ReportingManagerMaster/>}/>
          <Route exact path="/hiringmanagermaster" element={<HiringManagerMaster/>}/>
          <Route exact path="/skillsetmaster" element={<SkillsetMaster/>}/>
          <Route exact path="/jobpositionmaster" element={<JobPositionMaster/>}/> */}
          </Routes> 
           </RenderOnAnonymous>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
