import React,{useState,useEffect} from 'react'
import FooterPages from './FooterPages';
import UserService from '../services/UserService'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import getNotification from '../Redux/Action/action'
const {REACT_APP_UPLOAD_DATA}=process.env
const {REACT_APP_REDIRECT_URI}=process.env

function VendorMaster() {
    const [addnewscreen,setaddnewscreen]=useState(false);
const [addnewobject,setaddnewobject]=useState({})
const [reportingmanagerlist,setreportingmanagerlist]=useState([])
const dispatch=useDispatch()

useEffect(()=>{
  handlefetch()
},[])
const handladd = async () => {
  if (UserService.isLoggedIn()) {
    try {
      const FormData = require("form-data");
      const sendData = new FormData();
      sendData.append("jwttoken", UserService.getToken());

      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/brand/getbrands`,
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        data: sendData,
      };
      const getResponse = await axios(config);
      //console.log(getResponse);
      if (getResponse.data !== "Invalid User!") {
      handlefetch()
        dispatch(
          getNotification({
            message: "Reporting Manager successfully added",
            type: "success",
          })
        );
        // setbrandoptions(getResponse.data);
        // setselectedbrand(getResponse.data[0].brand);
      }
      else {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/reportingmanagermaster`,
        });
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 500) {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 400) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 422) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 404) {
        dispatch(
          getNotification({
            message: "Page not Found",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        );
        setTimeout(UserService.doLogin(), 1000)
      } else {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      }
    }
  } else {
    setTimeout(() => {
      UserService.doLogin({
        redirectUri: `${REACT_APP_REDIRECT_URI}/reportingmanagermaster`,
      });
    }, 1000);
  }
};
const handlefetch = async () => {
  if (UserService.isLoggedIn()) {
    try {
      const FormData = require("form-data");
      const sendData = new FormData();
      sendData.append("jwttoken", UserService.getToken());

      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/brand/getbrands`,
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        data: sendData,
      };
      const getResponse = await axios(config);
      //console.log(getResponse);
      if (getResponse.data !== "Invalid User!") {
        // setbrandoptions(getResponse.data);
        // setselectedbrand(getResponse.data[0].brand);
      }
      else {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/reportingmanagermaster`,
        });
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 500) {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 400) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 422) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 404) {
        dispatch(
          getNotification({
            message: "Page not Found",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        );
        setTimeout(UserService.doLogin(), 1000)
      } else {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      }
    }
  } else {
    setTimeout(() => {
      UserService.doLogin({
        redirectUri: `${REACT_APP_REDIRECT_URI}/reportingmanagermaster`,
      });
    }, 1000);
  }
};
  return (
    <>
    <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div className="modal-content">
              <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
                <h6 class="modal-title orangetheme" id="exampleModalLabel">
                  Add New Record
                </h6>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="closemodal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body py-3 px-4">
              <ol type="1">
          <li className='my-2'>
        <span className='row'>
  
        <label className='col-sm-4'>Vendor Id</label>
        <input className="col-sm-8 form-control" placeholder='Vendor Id'/>
        </span>
            </li>
                 <li className='my-2'>
            <span className='row'>
            <label className='col-sm-4'>Vendor Name</label>
            <input className="col-sm-8 form-control" placeholder='Vendor Name'/>
          </span>  </li>
            
                 <li className='my-2'>
            <span className='row'>
            <label className='col-sm-4'>Vendor Email</label>
            <input className="col-sm-8 form-control" placeholder='Vendor Email'/>
         </span>   </li>
        </ol>
       
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary btn-sm"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  onClick={() => {
                    if (true) {
                      document.getElementById("scenarionamebox").focus();
                    } else {
                     
  
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      <div>
        {/* <div><img src={bgpage} className='mx-auto'/></div> */}
  
       <div className='container  masterhomepage'  >
      <div className='p-3'>
      <h4 className='my-2 orangetheme'> Vendor Master</h4>
     
      <div className='d-flex flex-row-reverse my-3'>
  
       <i class="fa fa-plus-circle mx-1 text-success" style={{fontSize:"25px"}}  data-toggle={ "modal"}
       data-target="#exampleModal"></i>
      
       </div>
      <table className='table table-striped mt-4 table-sm'>
          <thead>
              <tr>
                  <th>S. No.</th>
                  <th>Vendor ID</th>
                  <th>Vendor Name</th>
                  <th>Vendor Email</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>1</td>
                  <td>Q090</td>
                  <td>Aayushi Gupta</td>
                 <td>aayushi@quation.in</td>
                  </tr>
                  <tr>
                  <td>2</td>
                  <td>Q095</td>
                  <td>Aishwarya</td>
                  <td>aishwarya@quation.in</td>
                 
                  </tr>
                  <tr>
                  <td>3</td>
                  <td>Q093</td>
                  <td>Deeptha</td>
                  <td>deeptha@quation.in</td>
                 
                  </tr>
  
          </tbody>
       </table>
      
  
  
      </div></div></div>
      <FooterPages/>
      </>
  )
}

export default VendorMaster