import React,{ useState,useEffect} from 'react'
import bgpage from "./Images/bgpage.jpg"
import FooterPages from './FooterPages'
import UserService from '../services/UserService'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import getNotification from '../Redux/Action/action'
const {REACT_APP_UPLOAD_DATA}=process.env
const {REACT_APP_REDIRECT_URI}=process.env

function ClientMaster() {

const [addnewscreen,setaddnewscreen]=useState(false);
const [addnewobject,setaddnewobject]=useState({})
const [clientlist,setclientlist]=useState([])
const [editobject, seteditobject] = useState({})
let counter=1;
const dispatch=useDispatch()

useEffect(()=>{
  handlefetch()
},[])

const handlefetch = async () => {
  if (UserService.isLoggedIn()) {
    try {
    
      const config = {
        method: "get",
        url: `${REACT_APP_UPLOAD_DATA}/getclient`,
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
   
      };
      const getResponse = await axios(config);
      console.log(getResponse);
      if (getResponse.data !== "Invalid User!") {
   
      setclientlist(getResponse.data)
   
        // setbrandoptions(getResponse.data);
        // setselectedbrand(getResponse.data[0].brand);
      }
      else {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/reporting_managers`,
        });
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 500) {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 400) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 422) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 404) {
        dispatch(
          getNotification({
            message: "Page not Found",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        );
        setTimeout(UserService.doLogin(), 1000)
      } else {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      }
    }
  } else {
    setTimeout(() => {
      UserService.doLogin({
        redirectUri: `${REACT_APP_REDIRECT_URI}/ClientMaster`,
      });
    }, 1000);
  }
};
const handleadd= async () => {
  if (UserService.isLoggedIn()) {
   if(addnewobject.client_name && addnewobject.location && addnewobject.vertical!=="Select"){
    try {
      const FormData = require("form-data");
      const sendData = new FormData();
      sendData.append("client_name",addnewobject.client_name);
      sendData.append("location",addnewobject.location);
      sendData.append("vertical",addnewobject.vertical);
      sendData.append("created_by",UserService.getUsername());
      sendData.append("status",addnewobject.status || "Active");
      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/client`,
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        data: sendData,
      };
      const getResponse = await axios(config);
      console.log(getResponse);
      if (getResponse.data !== "Invalid User!") {
        // setbrandoptions(getResponse.data);
        // setselectedbrand(getResponse.data[0].brand);
        document.getElementById("closemodal").click()
        setaddnewobject({client_name:""})
        handlefetch()
        dispatch(
          getNotification({
            message: "status successfully added",
            type: "success",
          })
        );
      }
      else {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/ClientMaster`,
        });
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 500) {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 400) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 422) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 404) {
        dispatch(
          getNotification({
            message: "Page not Found",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        );
        setTimeout(UserService.doLogin(), 1000)
      } else {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      }
    }
   }
   else{
    dispatch(
      getNotification({
        message: "Please fill all entries",
        type: "default",
      })
    );
   }
  } else {
    setTimeout(() => {
      UserService.doLogin({
        redirectUri: `${REACT_APP_REDIRECT_URI}/ClientMaster`,
      });
    }, 1000);
  }
};
const handleeditstatus = async (item) => {
  if (UserService.isLoggedIn()) {
    if (true) {
      try {  
        const sendData = {
     
          client_name: item.client_name,
          location:item.location,
        vertical:item.vertical,
          status: item.status === "Active" ? "Inactive" : "Active",
          created_by: UserService.getUsername()
        };

        const config = {
          method: "put",
          url: `${REACT_APP_UPLOAD_DATA}/client`,
          headers: {
            Accept: "application/json",  // API expects a JSON response
            "Content-Type": "application/json",  // Send data as JSON
          },
          data: JSON.stringify(sendData), // Convert your object to a JSON string
        };
        const getResponse = await axios(config);
        console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
          handlefetch()
          document.getElementById("closemodal2").click()
          seteditobject({ rec_id: "", rec_email_id: "", rec_name: "", status: "Active" })
          dispatch(
            getNotification({
              message: "Client successfully edited",
              type: "success",
            })
          );
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/clientmaster`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    }
    else {
      dispatch(
        getNotification({
          message: "Please fill all entries",
          type: "default",
        })
      );
    }
  } else {
    setTimeout(() => {
      UserService.doLogin({
        redirectUri: `${REACT_APP_REDIRECT_URI}/clientmaster`,
      });
    }, 1000);
  }
}
const handleedit = async () => {
  if (UserService.isLoggedIn()) {
    if (editobject.client_name && editobject.location) {
      try {
        const sendData = {
          client_name: editobject.client_name,
          location:editobject.location,
          vertical:editobject.vertical,
          status: editobject.status ,
          created_by: UserService.getUsername()
        };

        const config = {
          method: "put",
          url: `${REACT_APP_UPLOAD_DATA}/client`,
          headers: {
            Accept: "application/json",  // API expects a JSON response
            "Content-Type": "application/json",  // Send data as JSON
          },
          data: JSON.stringify(sendData), // Convert your object to a JSON string
        };
        const getResponse = await axios(config);
        console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
          handlefetch()
          document.getElementById("closemodal2").click()
          seteditobject({ rec_id: "", rec_email_id: "", rec_name: "", status: "Active" })
          dispatch(
            getNotification({
              message: "Client successfully edited",
              type: "success",
            })
          );
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/clientmaster`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    }
    else {
      dispatch(
        getNotification({
          message: "Please fill all entries",
          type: "default",
        })
      );
    }
  } else {
    setTimeout(() => {
      UserService.doLogin({
        redirectUri: `${REACT_APP_REDIRECT_URI}/clientmaster`,
      });
    }, 1000);
  }
}
  return (<>
       <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModalLabel">
              Add New Record
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-4">
    
    
               <div className='my-2'>
          <span className='row'>
          <label className='col-sm-4'>Client Name</label>
          <input className="col-sm-8 form-control" placeholder='Add Client' value={addnewobject.client_name} onChange={(e)=>setaddnewobject({...addnewobject,client_name:e.target.value})}/>
          
          </span>  </div>
          <div className='my-2'>
          <span className='row'>
          <label className='col-sm-4'>Client Location</label>
          <input className="col-sm-8 form-control" value={addnewobject.location} placeholder='Add Client Location' onChange={(e)=>setaddnewobject({...addnewobject,location:e.target.value})}/>
          
          </span> 
          <span className='row my-2'>
          <label className='col-sm-4'>Vertical</label>
          <select className="col-sm-8" value={addnewobject.vertical} placeholder='Add Client vertical' onChange={(e)=>setaddnewobject({...addnewobject,vertical:e.target.value})}>
            <option>Select</option><option>Technology</option>
            <option>CPG</option>
            <option>Retail</option>
          </select>
        
          
          </span> 
           </div>
          <div class=" float-end form-check form-switch">

<input
  className="form-check-input"
  type="checkbox"
  role="switch"
  id="flexSwitchCheckChecked"
  checked={addnewobject?.status === "Active" || addnewobject?.status == null}
  onChange={() => {
    if (addnewobject.status === "Active") { setaddnewobject({ ...addnewobject, status: "Inactive" }) } else {
      setaddnewobject({ ...addnewobject, status: "Active" })
    }
  }}
/>
<label class="form-check-label" for="flexSwitchCheckChecked">{addnewobject?.status === "Active" || addnewobject?.status == null ? "Active" : "Inactive"}</label>
</div>
     
     
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {
                 handleadd()

                  
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModal2Label"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModal2Label">
             Edit Record
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal2"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-4">
    
    
               <div className='my-2'>
          <span className='row'>
          <label className='col-sm-4'>Client Name</label>
          <input className="col-sm-8 form-control" placeholder='Add Client' value={editobject.client_name} onChange={(e)=>seteditobject({...editobject,client_name:e.target.value})}/>
          
          </span>  </div>
          <div className='my-2'>
          <span className='row'>
          <label className='col-sm-4'>Client Location</label>
          <input className="col-sm-8 form-control" placeholder='Add Client Location' value={editobject.location} onChange={(e)=>seteditobject({...editobject,location:e.target.value})}/>
          
          </span> 
          <span className='row my-2'>
          <label className='col-sm-4'>Vertical</label>
          <select className="col-sm-8" value={editobject.vertical} placeholder='Add Client vertical' onChange={(e)=>seteditobject({...editobject,vertical:e.target.value})}>
            <option>Select</option><option>Tech</option>
            <option>CPG</option>
            <option>Retail</option>
          </select>
        
          
          </span>  </div>
          <div class=" float-end form-check form-switch">

<input
  className="form-check-input"
  type="checkbox"
  role="switch"
  id="flexSwitchCheckChecked"
  checked={editobject?.status === "Active" || editobject?.status == null}
  onChange={() => {
    if (editobject.status === "Active") { seteditobject({ ...editobject, status: "Inactive" }) } else {
      seteditobject({ ...editobject, status: "Active" })
    }
  }}
/>
<label class="form-check-label" for="flexSwitchCheckChecked">{editobject?.status === "Active" || editobject?.status == null ? "Active" : "Inactive"}</label>
</div>
     
     
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {
                 handleedit()

                  
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    <div>
      {/* <div>
      <img src={bgpage} className='mx-auto'/></div> */}
      <div className='container masterhomepage '  >
    <div className='p-3'>
    <h4 className='my-2 orangetheme'> Clients</h4>
  
    <div className='d-flex flex-row-reverse my-3'>

<i class="fa fa-plus-circle mx-1 text-success" style={{fontSize:"25px"}}  data-toggle={ "modal"}
data-target="#exampleModal"></i>

</div>
 {clientlist?.length>0 ?   <table className='table table-striped mt-4 table-sm'>
  <thead className="" style={{backgroundColor:"#F79548",color:"white"}}>
           
                <th>S. No.</th>
                <th>Client Name</th>
                <th>Location</th>
                <th>vertical</th>
                <th>Status</th>
                <th></th>
            
        </thead>
        <tbody>
     

{clientlist?.map((item,index) => {
 
    return (
      <tr >
        <td>{index+1}</td> {/* Increment counter here */}
        <td>{item.client_name}</td>
        <td>{item.location}</td>
         <td>{item?.vertical}</td>
        <td><div class="form-check form-switch">

<input
  className="form-check-input"
  type="checkbox"
  role="switch"
  id="flexSwitchCheckChecked"
  checked={item.status === "Active"}
  onChange={()=>handleeditstatus(item)}
/>
  <label class="form-check-label" for="flexSwitchCheckChecked">{item.status === "Active"?"Active":"Inactive"}</label>
</div>
 </td>
 <td> <i className='fa fa-edit' onClick={() => { seteditobject(item) }} data-toggle={"modal"}
                      data-target="#exampleModal2"></i></td>

      </tr>
    );
  
})}
            {/* <tr>
                <td>1</td>
            
                <td>Data Modelling</td>
               
                </tr>
                <tr>
                <td>2</td>
               
                <td>Python</td>
              
               
                </tr>
                <tr>
                <td>3</td>
          <td>ReactJs</td>
                
               
                </tr>
<tr>
    <td>4</td>
    <td>Javascript</td>
</tr>
<tr>
    <td>5</td>
    <td>FastApi</td>
</tr> */}
        </tbody>
     </table>:<div>There is no record to display!!</div>}

 
    
    </div></div></div>
   <FooterPages/> </>
  )
}

export default ClientMaster