import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import UserService from "../services/UserService.js";
import quationlogo from './Images/quationlogoBg0.png'
import getNotification from "../Redux/Action/action.js";
import { useDispatch } from "react-redux";
import quationQ from './Images/quationQ.png'
const { REACT_APP_REDIRECT_URI } = process.env;
const { REACT_APP_UPLOAD_DATA } = process.env;

function Navbar2({scrollToSection}) {
  const dispatch = useDispatch();

  // useEffect(()=>{
  //   if (UserService.isLoggedIn()) {
  //     dispatch(getNotification({
  //       message: "You are successfully logged in",
  //       type: "success"
  //     }))
  //   }
  // },[])
  return (
    <>

      <nav className="navbar navbar-expand-sm sticky-top  navbar-light bg-light" style={{ zIndex: 4 }}>
        <a className="navbar-brand " href="/">
        <img src={quationQ} style={{ height: "32px" }} />
           {" "}Recruitment Tool
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="">
          <div style={{width: "30px",height: "3px", backgroundColor:"white",margin:"6px"}}></div>
          <div style={{width: "30px",height: "3px", backgroundColor:"white",margin:"6px"}}></div>
          <div style={{width: "30px",height: "3px", backgroundColor:"white",margin:"6px"}}></div>
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
           
       
      
          
            <li
              className={UserService.isLoggedIn()?"nav-item-active ml-3":"d-none"}         
            >
               <a class="nav-link" href="/dashboard">
               <i class='fab fa-accusoft'></i>Dashboard
               </a>
          
            </li>
         
            <li class={ UserService.isLoggedIn()?"nav-item dropdown mx-2":"d-none"}>
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fa fa-gear mx-1"></i>Settings
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="/reportingmanagermaster">Reporting Manager Master</a>
          <a class="dropdown-item" href="/hiringmanagermaster">Hiring Manager Master</a>
      
          <a class="dropdown-item" href="/skillsetmaster"> Skillset Master</a>
          <a class="dropdown-item" href="/jobpositionmaster"> Job Position Master</a>
          <a class="dropdown-item" href="/recruitmentmanagermaster">Recruitment Head Master</a>
          <a class="dropdown-item" href="/vendormaster">Vendor </a>
          <a class="dropdown-item" href="/statusmaster">Status </a>
          <a class="dropdown-item" href="/clientmaster">Client </a>
          <a class="dropdown-item" href="/departmentmaster">Department </a>
        </div>
      </li>
            {/* <li
                className={
                  UserService.isLoggedIn()
                    ? " dropdown nav-item active"
                    : "d-none"
                }
              >

                <div>
                  <button
                    className="btn  dropdown-toggle nav-item mt-2"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{color:"balck"}}
                  >
                    <i className="fas fa-clipboard-list mr-1"></i>
                    Diagnostics
                  </button>

                  <div
                    className="dropdown-menu "
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a className="dropdown-item" href="/competitiveanalysis">
                      Competitive Analysis{" "}
                    </a>
                    <a className="dropdown-item" href="/brandanalysis">
                      Brand Analysis
                    </a>

                  </div>
                </div>

              </li>

              <li
                className={
                  UserService.isLoggedIn() ? "nav-item" : "d-none"
                }
              >
                <Link className="nav-link" to="/simulator">
                  <button className=" btn" style={{color:"balck"}}>
                    Simulator
                  </button>
                </Link>
              </li>
              <li
                className={
                  UserService.isLoggedIn() ? "nav-item active" : "d-none"
                }
              >
                <Link className="nav-link" to="/optimizer">
                  <button className="btn btn-sm  noborder" style={{color:"balck"}}>
                    Optimizer
                  </button>
                </Link>
              </li>
              <li
                className={
                  UserService.isLoggedIn() ? "nav-item active" : "d-none"
                }
              >
                <Link className="nav-link" to="/eventmanagement">
                  <button className="btn btn-sm  noborder" style={{color:"balck"}}>
                  Event Management
                  </button>
                </Link>
              </li>
           
              <li
                className={
                  UserService.isLoggedIn() ? "nav-item active" : "d-none"
                }
              >
                <Link className="nav-link" to="/savedreports">
                  <button className="btn btn-sm  noborder" style={{color:"balck"}}>
                    Saved Reports
                  </button>
                </Link>
              </li> */}

          </ul>

       
          <ul className="navbar-nav ml-auto">
            <li
              className={
                UserService.isLoggedIn() ? "nav-item active" : "d-none"
              }
            >
              <Link className="nav-link" to="/">
                <button className="btn btn-sm  noborder"  >
                  <i className="fa fa-user" aria-hidden="true"></i> Hi,{" "}
                  {UserService.getFullName()?.split(" ")[0]}
                </button>
              </Link>
            </li>
            <li
              className={
                UserService.isLoggedIn() ? "nav-item active" : "d-none"
              }
            >
              <Link className="nav-link" to="/support">
                <button className="btn btn-sm  noborder" >
                  <i className="fa fa-user" aria-hidden="true"></i> Support
                </button>
              </Link>
            </li>
            <li
              className={
                UserService.isLoggedIn() ? "d-none" : "nav-item active"
              }
            >
              <Link
                className="nav-link"
                onClick={() => {
                  UserService.doLogin({
                    redirectUri:`${REACT_APP_REDIRECT_URI}/dashboard`,
                  })
                }}
              >
                <button className="btn btn-sm  noborder" >
                  <i className="fas fa-sign-in-alt"></i> Login
                </button>
              </Link>
            </li>
            <li
              className={
                UserService.isLoggedIn() ? "d-none" : "nav-item active"
              }
            >
              <Link
                className="nav-link"
                onClick={() => UserService.doSignUp()}
              >
                <button className="btn btn-sm  noborder" >
                  <i className="fas fa-user-plus"></i> SignUp
                </button>
              </Link>
            </li>

            <li
              className={
                UserService.isLoggedIn() ? "nav-item active" : "d-none"
              }
            >
              <Link
                className="nav-link"
                onClick={() => {
                  UserService.doLogout({
                    redirectUri: `${REACT_APP_REDIRECT_URI}`,
                  });
                  // console.log(UserService.getToken());
                }}
              >
                <button className="btn btn-sm  noborder" >
                  <i className="fas fa-sign-in-alt"></i> Logout
                </button>
              </Link>
            </li>
          </ul>
      
        </div>

     
      </nav>
    </>
  );
}

export default Navbar2;
