import React, { useState, useEffect } from 'react'
import bgpage from "./Images/bgpage.jpg"
import FooterPages from './FooterPages'
import UserService from '../services/UserService'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import getNotification from '../Redux/Action/action'
const { REACT_APP_UPLOAD_DATA } = process.env
const { REACT_APP_REDIRECT_URI } = process.env
function RecruitmentManagerMaster() {
  const [addnewscreen, setaddnewscreen] = useState(false);
  const [addnewobject, setaddnewobject] = useState({})
  const [recruitmentmanagerlist, setrecruitmentmanagerlist] = useState([])
  const [editobject, seteditobject] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    handlefetch()
  }, [])
  const handlefetch = async () => {
    if (UserService.isLoggedIn()) {
      try {

        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/recruitment_Managers`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {

          setrecruitmentmanagerlist(getResponse.data)
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/Recruitment_Managers`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/recruitmentmanagermaster`,
        });
      }, 1000);
    }
  };
  const handleadd = async () => {
    if (UserService.isLoggedIn()) {
      if (addnewobject.rec_id && addnewobject.rec_name && addnewobject.rec_email_id ) {
        try {
          const FormData = require("form-data");
          const sendData = new FormData();
          sendData.append("rec_id", addnewobject.rec_id);
          sendData.append("rec_name", addnewobject.rec_name);
          sendData.append("rec_email_id", addnewobject.rec_email_id);
          sendData.append("created_by", UserService.getUsername());
          sendData.append("status", addnewobject.status || "Active");
          const config = {
            method: "post",
            url: `${REACT_APP_UPLOAD_DATA}/recruitment_manager`,
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
            data: sendData,
          };
          const getResponse = await axios(config);
          console.log(getResponse);
          if (getResponse.data !== "Invalid User!") {
            // setbrandoptions(getResponse.data);
            // setselectedbrand(getResponse.data[0].brand);
            handlefetch()
            document.getElementById("closemodal").click()
            setaddnewobject({ rec_id: "", rec_email_id: "", rec_name: "" })
            dispatch(
              getNotification({
                message: "Recruiter successfully added",
                type: "success",
              })
            );
          }
          else {
            UserService.doLogin({
              redirectUri: `${REACT_APP_REDIRECT_URI}/recruitmentmanagermaster`,
            });
          }
        } catch (err) {
          console.log("Server Error", err);
          if (err.response && err.response.status === 500) {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 400) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 422) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 404) {
            dispatch(
              getNotification({
                message: "Page not Found",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
            setTimeout(UserService.doLogin(), 1000)
          } else {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          }
        }
      }
      else {
        dispatch(
          getNotification({
            message: "Please fill all entries",
            type: "default",
          })
        );
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/recruitmentmanagermaster`,
        });
      }, 1000);
    }
  };
  const handleeditstatus = async (item) => {
    if (UserService.isLoggedIn()) {
      if (true) {
        try {
          const sendData = {
            rec_id: item.rec_id,
            rec_name: item.rec_name,
            rec_email_id: item.rec_email_id,
            status: item.status === "Active" ? "Inactive" : "Active",
            created_by: UserService.getUsername()
          };

          const config = {
            method: "put",
            url: `${REACT_APP_UPLOAD_DATA}/recruitment_manager`,
            headers: {
              Accept: "application/json",  // API expects a JSON response
              "Content-Type": "application/json",  // Send data as JSON
            },
            data: JSON.stringify(sendData), // Convert your object to a JSON string
          };
          const getResponse = await axios(config);
          console.log(getResponse);
          if (getResponse.data !== "Invalid User!") {
            // setbrandoptions(getResponse.data);
            // setselectedbrand(getResponse.data[0].brand);
            handlefetch()
            document.getElementById("closemodal2").click()
            seteditobject({ rec_id: "", rec_email_id: "", rec_name: "", status: "Active" })
            dispatch(
              getNotification({
                message: "Recruitment Manager successfully edited",
                type: "success",
              })
            );
          }
          else {
            UserService.doLogin({
              redirectUri: `${REACT_APP_REDIRECT_URI}/Recruitmentmanagermaster`,
            });
          }
        } catch (err) {
          console.log("Server Error", err);
          if (err.response && err.response.status === 500) {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 400) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 422) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 404) {
            dispatch(
              getNotification({
                message: "Page not Found",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
            setTimeout(UserService.doLogin(), 1000)
          } else {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          }
        }
      }
      else {
        dispatch(
          getNotification({
            message: "Please fill all entries",
            type: "default",
          })
        );
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/Recruitmentmanagermaster`,
        });
      }, 1000);
    }
  }
  const handleedit = async () => {
    if (UserService.isLoggedIn()) {
      if (editobject.rec_id && editobject.rec_name && editobject.rec_email_id && editobject.status) {
        try {
          const sendData = {
            rec_id: editobject.rec_id,
            rec_name: editobject.rec_name,
            rec_email_id: editobject.rec_email_id,
            status: editobject.status,
            created_by: UserService.getUsername()
          };

          const config = {
            method: "put",
            url: `${REACT_APP_UPLOAD_DATA}/recruitment_manager`,
            headers: {
              Accept: "application/json",  // API expects a JSON response
              "Content-Type": "application/json",  // Send data as JSON
            },
            data: JSON.stringify(sendData), // Convert your object to a JSON string
          };
          const getResponse = await axios(config);
          console.log(getResponse);
          if (getResponse.data !== "Invalid User!") {
            // setbrandoptions(getResponse.data);
            // setselectedbrand(getResponse.data[0].brand);
            handlefetch()
            document.getElementById("closemodal2").click()
            seteditobject({ rec_id: "", rec_email_id: "", rec_name: "", status: "Active" })
            dispatch(
              getNotification({
                message: "Recruitment Manager successfully edited",
                type: "success",
              })
            );
          }
          else {
            UserService.doLogin({
              redirectUri: `${REACT_APP_REDIRECT_URI}/Recruitmentmanagermaster`,
            });
          }
        } catch (err) {
          console.log("Server Error", err);
          if (err.response && err.response.status === 500) {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 400) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 422) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 404) {
            dispatch(
              getNotification({
                message: "Page not Found",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
            setTimeout(UserService.doLogin(), 1000)
          } else {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          }
        }
      }
      else {
        dispatch(
          getNotification({
            message: "Please fill all entries",
            type: "default",
          })
        );
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/Recruitmentmanagermaster`,
        });
      }, 1000);
    }
  }
  return (

    <>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModalLabel">
                Add New Record
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body py-3 px-4">
              <ol type="1">
                <li className='my-2'>


                  <span className='row'>

                    <label className='col-sm-4'>Employee Id</label>
                    <input className="col-sm-8 form-control" placeholder='Employee Id' value={addnewobject.rec_id} onChange={(e) => setaddnewobject({ ...addnewobject, rec_id: e.target.value })} />
                  </span>
                </li>
                <li className='my-2'>
                  <span className='row'>
                    <label className='col-sm-4'>Employee Name</label>
                    <input className="col-sm-8 form-control" placeholder='Employee Name' value={addnewobject.rec_name} onChange={(e) => setaddnewobject({ ...addnewobject, rec_name: e.target.value })} />
                  </span>  </li>

                <li className='my-2'>
                  <span className='row'>
                    <label className='col-sm-4'>Employee Email</label>
                    <input className="col-sm-8 form-control" placeholder='Employee Email' value={addnewobject.rec_email_id} onChange={(e) => setaddnewobject({ ...addnewobject, rec_email_id: e.target.value })} />
                  </span>   </li>
                <div class=" float-end form-check form-switch">

                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    checked={addnewobject?.status === "Active" || addnewobject?.status == null}
                    onChange={() => {
                      if (addnewobject.status === "Active") { setaddnewobject({ ...addnewobject, status: "Inactive" }) } else {
                        setaddnewobject({ ...addnewobject, status: "Active" })
                      }
                    }}
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked">{addnewobject?.status === "Active" || addnewobject?.status == null ? "Active" : "Inactive"}</label>
                </div>
              </ol>

            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {
                  handleadd()
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModal2Label"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModal2Label">
                Edit Record
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal2"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body py-3 px-4">
              <ol type="1">
                <li className='my-2'>
                  <span className='row'>

                    <label className='col-sm-4'>Employee Id</label>
                    <input className="col-sm-8 form-control" placeholder='Employee Id' value={editobject.rec_id} onChange={(e) => seteditobject({ ...editobject, rec_id: e.target.value })} />
                  </span>
                </li>
                <li className='my-2'>
                  <span className='row'>
                    <label className='col-sm-4'>Employee Name</label>
                    <input className="col-sm-8 form-control" placeholder='Employee Name' value={editobject.rec_name} onChange={(e) => seteditobject({ ...editobject, rec_name: e.target.value })} />
                  </span>  </li>

                <li className='my-2'>
                  <span className='row'>
                    <label className='col-sm-4'>Employee Email</label>
                    <input className="col-sm-8 form-control" placeholder='Employee Email' value={editobject.rec_email_id} onChange={(e) => seteditobject({ ...editobject, rec_email_id: e.target.value })} />
                  </span>   </li>
                <div class=" float-end form-check form-switch">

                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    checked={editobject?.status === "Active" || editobject?.status == null}
                    onChange={() => {
                      if (editobject.status === "Active") { seteditobject({ ...editobject, status: "Inactive" }) } else {
                        seteditobject({ ...editobject, status: "Active" })
                      }
                    }}
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked">{editobject?.status === "Active" || editobject?.status == null ? "Active" : "Inactive"}</label>
                </div>
              </ol>

            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
                id="closemodal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {

                  handleedit()

                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <div><img src={bgpage} className='mx-auto'/></div> */}

        <div className='container  masterhomepage'  >
          <div className='p-3'>
            <h4 className='my-2 orangetheme'> Recruiters</h4>

            <div className='d-flex flex-row-reverse my-3'>

              <i class="fa fa-plus-circle mx-1 text-success" style={{ fontSize: "25px" }} data-toggle={"modal"}
                data-target="#exampleModal"></i>

            </div>
            <table className='table table-striped mt-4 table-sm'>
            <thead className="" style={{backgroundColor:"#F79548",color:"white"}}>
           
                  <th>S. No.</th>
                  <th>Employee ID</th>
                  <th>Employee Name</th>
                  <th>Employee Email</th>
                  <th>Status</th>
                  <th></th>
             
              </thead>
              <tbody>
                {recruitmentmanagerlist?.map((item, index) => {
                  return <tr>
                    <td>{index + 1}</td>
                    <td>{item.rec_id}</td>
                    <td>{item.rec_name}</td>
                    <td>{item.rec_email_id}</td>
                    <td>
                      <div class="form-check form-switch">

                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked={item.status === "Active"}
                          onChange={() => handleeditstatus(item)}
                        />
                        <label class="form-check-label" for="flexSwitchCheckChecked">{item.status === "Active" ? "Active" : "Inactive"}</label>
                      </div>
                    </td>
                    <td> <i className='fa fa-edit' onClick={() => { seteditobject(item) }} data-toggle={"modal"}
                      data-target="#exampleModal2"></i></td>

                  </tr>
                })}
                {/* <tr>
                <td>1</td>
                <td>Q090</td>
                <td>Aayushi Gupta</td>
               <td>aayushi@quation.in</td>
                </tr>
                <tr>
                <td>2</td>
                <td>Q095</td>
                <td>Aishwarya</td>
                <td>aishwarya@quation.in</td>
               
                </tr>
                <tr>
                <td>3</td>
                <td>Q093</td>
                <td>Deeptha</td>
                <td>deeptha@quation.in</td>
               
                </tr> */}

              </tbody>
            </table>



          </div></div></div>
      <FooterPages />
    </>
  )
}

export default RecruitmentManagerMaster