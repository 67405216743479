import React,{ useState,useEffect} from 'react'
import bgpage from "./Images/bgpage.jpg"
import FooterPages from './FooterPages'
import Select from "react-select";
import UserService from '../services/UserService'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import getNotification from '../Redux/Action/action'
const {REACT_APP_UPLOAD_DATA}=process.env
const {REACT_APP_REDIRECT_URI}=process.env
function JobPositionMaster() {
  const [addnewscreen,setaddnewscreen]=useState(false);
  const [addnewobject,setaddnewobject]=useState({})
  const [reportingmanagerlist,setreportingmanagerlist]=useState([])
  const [jobpositionlist,setjobpositionlist]=useState([])
  const [skillsetlist,setskillsetlist]=useState([])
  const dispatch=useDispatch()
  useEffect(()=>{
    handlefetch()
    handlefetchskills()
  },[])
  const handlefetchskills = async () => {
    if (UserService.isLoggedIn()) {
      try {
      
        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/skills`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
     
        };
        const getResponse = await axios(config);
        console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
     let arr=[]
     {getResponse.data?.map((item)=>{
       item.skill.split(",")?.map((it,index)=>{
        arr.push({label:it,value:it})
       })
    
     })}
    console.log(arr)
        setskillsetlist(arr)
     
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
        });
      }, 1000);
    }
  };
  const handleadd = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
        sendData.append("job_title", addnewobject.jobposition);
        sendData.append("skillset", addnewobject.skills.join(","));
        sendData.append("created_by",UserService.getUsername());
        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA}/job_position_master`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: sendData,
        };
        const getResponse = await axios(config);
        //console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
        handlefetch()
        document.getElementById("closemodal").click()
        setaddnewobject({jobposition:"",skills:[]})
          dispatch(
            getNotification({
              message: "Job Position successfully added",
              type: "success",
            })
          );
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
        });
      }, 1000);
    }
  };
  const handlefetch = async () => {
    if (UserService.isLoggedIn()) {
      try {
      
  
        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/job_Postion`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        //console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
          setjobpositionlist(getResponse.data)
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
        });
      }, 1000);
    }
  };
  return (
    <>
        <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModalLabel">
                Add New Record
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-4">
            <ol type="1">
        <li className='my-2'>
      <span className='row'>

      <label className='col-sm-4'>Job Position</label>
      <input className="col-sm-8 form-control" placeholder='Job Position' value={addnewobject.jobposition} onChange={(e)=>setaddnewobject({...addnewobject,jobposition:e.target.value})}/>
      </span>
          </li>
               <li className='my-2'>
          <span className='row'>
          <label className='col-sm-3'>Skillset</label>
          {/* <input className="col-sm-8 form-control" placeholder='Skillset'/> */}
          <span className='col-sm-9'>
      
          <Select className=" ml-4 custom-select-width2 " options={skillsetlist}  isSearchable isMulti  onChange={(value)=>{setaddnewobject({...addnewobject,skills:value?.map((it)=> it.value)})}} value={addnewobject?.skills?.map((it) => ({ label: it, value: it }))}/></span>
        </span>  </li>
         
      </ol>
     
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {
            handleadd()
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    <div>
      {/* <div><img src={bgpage} className='mx-auto'/></div> */}
     <div className='container masterhomepage '  >
    <div className='p-3'>
    <h4 className='my-2 orangetheme'>Job Positions </h4>

    <div className='d-flex flex-row-reverse my-3'>

<i class="fa fa-plus-circle mx-1 text-success" style={{fontSize:"25px"}}  data-toggle={ "modal"}
data-target="#exampleModal"></i>

</div>
    <table className='table table-striped mt-4 table-sm'>
    <thead className="" style={{backgroundColor:"#F79548",color:"white"}}>
       
                <th>S. No.</th>
                <th>Job Position</th>
                <th>Skillset</th>
                
        
        </thead>
        <tbody>
        {jobpositionlist?.map((item,index)=>{
            return <tr>
<td>{index+1}</td>
<td>{item.job_title}</td>
<td>{item.skillset}</td>

            </tr>
          })}

        </tbody>
     </table>
 
  
    </div></div>
    </div>
    <FooterPages/>
    </>
  )
}

export default JobPositionMaster