import React, { useState, useEffect } from 'react'
import Navbar2 from './Navbar2'
import FooterPages from './FooterPages'
import Select from "react-select";
import bgpage from "./Images/bgpage.jpg"
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import UserService from '../services/UserService'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import getNotification from '../Redux/Action/action'
const { REACT_APP_UPLOAD_DATA } = process.env
const { REACT_APP_REDIRECT_URI } = process.env

function Dashboard() {
  const navigate = useNavigate()
  const location = useLocation()
  const [filterstatus, setfilterstatus] = useState("Select")
  const [jobpostingfilteredlist, setjobpostingfilteredlist] = useState([])
  const [skillsetlist, setskillsetlist] = useState([])
  const [technicalskillsoptions, settechnicalskillsoptions] = useState([])
  const [softskillsoptions, setsoftskillsoptions] = useState([])
  const [newjobcreationscreen, setnewjobcreationscreen] = useState(false)
  const [newjobcreationvalue, setnewjobcreationvalue] = useState({})
  const [clientcity, setclientcity] = useState("");
  const [startdatestate, setstartdatestate] = useState("");
  const [enddatestate, setenddatestate] = useState("");
  const [statusfilter, setstatusfilter] = useState("");
  const [createdbyfilter, setcreatedbyfilter] = useState("");
  const [SliderValue, setSliderValue] = useState(0)
  const [SliderValue2, setSliderValue2] = useState(15)
  const [jobpostinglist, setjobpostinglist] = useState([])
  const [employeelist, setemployeelist] = useState([])
  const [jobpositionlist, setjobpositionlist] = useState([])
  const [clientlist, setclientlist] = useState([])
  const [viewobject, setviewobject] = useState("");
  const [jobrolelist, setjobrolelist] = useState([
    "Data Scientist/Data Engineers", "Account Managers/Senior Data Engineer", "Sales Head/Delivery Head", "Business Head"
  ])
  const [verticallist, setverticallist] = useState([
    "Technology", "CPG", "Pre-Sales", "HR", "Leadership", "Product", "Retail", "Retail-Sales", "Marketing", "Partnership", "CPG - Sales", "L & D", "Admin", "IT"
  ])
  const [designationlist, setdesignationlist] = useState([
    "Intern", "Consultant", "Senior Consultant", "Associate Manager", "Manager", "Senior Manager", "AVP-Assistant Vice President", "VP-Vice President", "SVP-Senior Vice President", "CXO"

  ])
  const [previewscreen, setpreviewscreen] = useState(false)
  const dispatch = useDispatch()
  const initialnewjobcreationvalue = {
    designation: "Select",
    title: "",
    jobposition: "Select",
    jobtitle: "",
    mandatoryskills: [],
    goodtohaveskills: [],
    softskills: [],
    summary: "",
    jobtype: "",
    reasonrecruit: "",
    noticeperiod: "Select",
    workinglocation: "Select",
    workinglocationoth: "",
    budgetbandmin: "",
    budgetbandmax: "",
    customer: "Select",
    customersub: "Select",
    noofpositions: "",
    panelist: [],
    // Add Slider values as well if applicable
  };

  useEffect(() => {
    handlefetch()
    handlefetchskills()
    handlefetchjobpositions()
    handlefetchclientlist()
    handlefetchemployees()

  }, [])
  const handlefetchemployees = async () => {
    if (UserService.isLoggedIn()) {
      try {

        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/employee`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {

          setemployeelist(getResponse?.data?.map((it) => {
            return { label: it.emp_name, value: it.emp_name }
          }))
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/reporting_managers`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };
  const handlefiltervariables = (type, value) => {
    let filteredList = [...jobpostinglist]
 
    if (type === "status") {
      setstatusfilter(value)
    }
    else if (type === "created_by") {
      setcreatedbyfilter(value)
   }
    else if (type === "startdate") {
      setstartdatestate(value)
    }
    else if (type === "enddate") {
      setenddatestate(value)
    }

  }
  const handlefilteroperations = () => {
    let filteredList = [...jobpostinglist]
   
    if (statusfilter) {
      if (statusfilter !== "Select") {
       filteredList = filteredList.filter((it) =>
          it.status === statusfilter
        );
      }
        }
    else if (createdbyfilter) {
      if (createdbyfilter !== "Select") {

        filteredList = filteredList.filter((it) =>
          it.created_by === createdbyfilter
        );
      }
    }
 

    else  if (startdatestate && enddatestate) {
      console.log(startdatestate,enddatestate)
      if(startdatestate>enddatestate){
        dispatch(getNotification({
          message:"Start Date is greater than end date",
          type:"danger"
        }))
      }
  
      else{
        filteredList = filteredList?.filter((item) => {
          const dateCreated = new Date(item.date_created.split(" ")[0]); // Convert 'created_on' to a Date object

          // Convert 'value' (start date) and 'enddatestate' (end date) to Date objects
          const startDate = new Date(startdatestate);
          const endDate = new Date(enddatestate);

          // Check if 'dateCreated' lies between 'startDate' and 'endDate'
          return dateCreated.getTime() >= startDate.getTime() && dateCreated.getTime() <= endDate.getTime();
        });
      }
       
  


    }
  
    setjobpostingfilteredlist(filteredList);
  }
  const handlefetchclientlist = async () => {
    if (UserService.isLoggedIn()) {
      try {

        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/getclient`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {

          setclientlist(getResponse.data)

          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };
  const handlepreviewscreen = () => {
    if (newjobcreationvalue.designation && newjobcreationvalue.jobposition
      && newjobcreationvalue.mandatoryskills?.length > 0 &&
      newjobcreationvalue.goodtohaveskills?.length > 0
      && newjobcreationvalue.softskills?.length > 0
      && SliderValue && SliderValue2 &&
      newjobcreationvalue.summary &&
      newjobcreationvalue.jobtype && newjobcreationvalue.reasonrecruit
      && newjobcreationvalue.noticeperiod && newjobcreationvalue.noticeperiod !== "Select"
      && newjobcreationvalue.workinglocation && newjobcreationvalue.budgetbandmin && newjobcreationvalue.budgetbandmax
      && newjobcreationvalue.customer && newjobcreationvalue.customer !== "Select" &&
      newjobcreationvalue.customersub && newjobcreationvalue.customersub !== "Select" &&
      newjobcreationvalue.noofpositions


    ) {

      if (newjobcreationvalue.jobposition === "Others") {
        if (newjobcreationvalue.jobtitle && newjobcreationvalue.jobtitle.trim() !== "") {
          setpreviewscreen(true);

        } else {
          dispatch(getNotification({
            message: "Please fill all entries",
            type: "default",
          }));
        }
      }
      else {
        setpreviewscreen(true);

      }

    }
    else {
      setpreviewscreen(false)
      dispatch(getNotification({
        message: "Please fill all entries",
        type: "default",
      }))
    }
  }
  const handlefetchjobpositions = async () => {
    if (UserService.isLoggedIn()) {
      try {


        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/job_Postion`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        //console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
          setjobpositionlist(getResponse.data)
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };
  const formatdate = (date) => {
    if (date === "open" || date === "Open" || typeof (date) !== "string" || date === null) { return "Open" }
    else {
      const onlydate = String(date.split(" ")[0])


      return `${onlydate.split("-")[2]}-${onlydate.split("-")[1]}-${onlydate.split("-")[0]}`
    }
  }
  const formattime = (time) => {
    if (time === "open" || time === "Open" || typeof (time) !== "string" || time === null) { return "Open" }
    else {

      return String(time.slice(11, 19))
    }
  }
  const capitalizefirstleter = (str) => {

    if (str?.length === 0 || str === null) return "Open"; // Check if the string is empty
    return str.charAt(0).toUpperCase() + str.slice(1);
  }


  const handlefetchskills = async () => {
    if (UserService.isLoggedIn()) {
      try {

        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/skills`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          let arrt = []
          let arrs = []
          {
            getResponse.data?.filter((it) => it.skill_type === "Technical").map((item) => {
              item.skill.split(",")?.map((it, index) => {
                arrt.push({ label: it, value: it })
              })

            })
          }
          {
            getResponse.data?.filter((it) => it.skill_type === "Soft").map((item) => {
              item.skill.split(",")?.map((it, index) => {
                arrs.push({ label: it, value: it })
              })

            })
          }

          settechnicalskillsoptions(arrt)
          setsoftskillsoptions(arrs)

          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
        });
      }, 1000);
    }
  };
  const handlefetch = async () => {
    if (UserService.isLoggedIn()) {
      try {

        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/job_postings`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          setjobpostinglist(getResponse.data);
          setjobpostingfilteredlist(getResponse.data)
          //setjobpostinglist(getResponse.data.sort((a, b) => b.date_created - a.date_created));
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };
  const handleadd = async () => {
    if (UserService.isLoggedIn()) {


      try {
        const FormData = require("form-data");
        const sendData = new FormData();


        sendData.append("job_position", newjobcreationvalue.jobposition === "Others" ? `Others ${newjobcreationvalue.jobtitle}` : newjobcreationvalue.jobposition);
        sendData.append("mandatory_skillset", newjobcreationvalue.mandatoryskills.join(","));
        sendData.append("good_to_have_skillset", newjobcreationvalue.goodtohaveskills.join(","));
        sendData.append("soft_skills", newjobcreationvalue.softskills.join(","));
        sendData.append("panel_list", newjobcreationvalue.panelist.join(","));
        sendData.append("relevant_experience_min", Number(SliderValue))
        sendData.append("relevant_experience_max", Number(SliderValue2))
        sendData.append("notice_period_days", newjobcreationvalue.noticeperiod)
        sendData.append("job_type", newjobcreationvalue.jobtype)
        sendData.append("reason_for_recruitment", newjobcreationvalue.reasonrecruit)
        newjobcreationvalue.workinglocation === "Client" ? sendData.append("preferred_working_location", `Client ${newjobcreationvalue.workinglocationoth}`) : sendData.append("preferred_working_location", newjobcreationvalue.workinglocation)
        sendData.append("budget_min_range", newjobcreationvalue.budgetbandmin)
        sendData.append("budget_max_range", newjobcreationvalue.budgetbandmax)
        sendData.append("customer_type", newjobcreationvalue.customer)
        sendData.append("customer", newjobcreationvalue.customersub)
        sendData.append("no_of_positions", newjobcreationvalue.noofpositions)
        sendData.append("date_closed", "")
        sendData.append("status", "open")
        sendData.append("designation", newjobcreationvalue.designation)
        sendData.append("summary", newjobcreationvalue.summary)
        sendData.append("created_by", UserService.getUsername());

        console.log(sendData)

        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA}/job_posting`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: sendData,
        };
        const getResponse = await axios(config);
        console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
          
          setSliderValue(0)
          setSliderValue2(15)
          setnewjobcreationvalue(initialnewjobcreationvalue)
          setnewjobcreationscreen(false)
          handlefetch()
          document.getElementById("closemodal").click()
          dispatch(
            getNotification({
              message: "Job Posting generated successfully",
              type: "success",
            })
          );
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    }
    else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };

  const onSliderChange = (value) => {
    setSliderValue(value);
  };
  const onSliderChange2 = (value) => {
    setSliderValue2(value);
  };

  return (
    <>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModalLabel">
                Preview
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal"
                onClick={() => setpreviewscreen(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-4">


              <div className='my-2'>
                Details entered mentioned below:
                <span className='row p-3'>

                  <table className="table table-sm table-striped">
                    {/* <thead>
    <tr>
      <th scope="col">Field</th>
      <th scope="col">Value</th>
    </tr>
  </thead> */}
                    <tbody>
                      <tr>
                        <th scope="row">Designation<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.designation}</td>
                      </tr>
                      <tr>
                        <th scope="row">Job Title<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.jobposition === "Others" ? `Others ${newjobcreationvalue.jobtitle}` : newjobcreationvalue.jobposition}</td>
                      </tr>

                      <tr>
                        <th scope="row">Mandatory Skills<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.mandatoryskills?.join(",")}</td>
                      </tr>
                      <tr>
                        <th scope="row">Good to Have Skills<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.goodtohaveskills?.join(",")}</td>
                      </tr>
                      <tr>
                        <th scope="row">Soft Skills<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue?.softskills}</td>
                      </tr>
                      <tr>
                        <th scope="row">Summary<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue?.summary}</td>
                      </tr>
                      <tr>
                        <th scope="row">Experience(in years)<span className="text-danger">*</span> </th>
                        <td> {SliderValue}-{SliderValue2}</td>
                      </tr>
                      <tr>
                        <th scope="row">Notice Period (in days)<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.noticeperiod}</td>
                      </tr>
                      <tr>
                        <th scope="row">Job Type<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.jobtype}</td>
                      </tr>
                      <tr>
                        <th scope="row">Reason of Recruitment<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.reasonrecruit}</td>
                      </tr>
                      <tr>
                        <th scope="row">Preferred Working Location<span className="text-danger">*</span></th>
                        <td>
                          {newjobcreationvalue.workinglocation === "Client" ? newjobcreationvalue.workinglocationoth : newjobcreationvalue.workinglocation}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Budget Band(in lacs)<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.budgetbandmin}-{newjobcreationvalue.budgetbandmax}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.customer}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer Name<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.customersub}</td>
                      </tr>
                      <tr>
                        <th scope="row">No. of Positions<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.noofpositions}</td>
                      </tr>
                      <tr>
                        <th scope="row">Panelist<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue?.panelist?.join(",")}</td>
                      </tr>
                    </tbody>
                  </table>
                  Please check before submitting!
                  {/* <label className='col-sm-12 '>Designation: {newjobcreationvalue.designation}</label>
          <label className='col-sm-12 '>Job Position: {newjobcreationvalue.jobposition}</label>
          <label className='col-sm-12 '>Title of job: {newjobcreationvalue.jobtitle}</label>
          <label className='col-sm-12 '>Mandatory Skills: {newjobcreationvalue.mandatoryskills}</label>
          <label className='col-sm-12 '>Good to Have Skills: {newjobcreationvalue.goodtohaveskills}</label>
          <label className='col-sm-12 '>Soft Skills: {newjobcreationvalue.softskills}</label>
          <label className='col-sm-12 '>Summary: {newjobcreationvalue.summary}</label>
          <label className='col-sm-12 '>Experience Min: {SliderValue} Max: {SliderValue2}</label>
          <label className='col-sm-12 '>Notice Period(in days): {newjobcreationvalue.noticeperiod}</label>
          <label className='col-sm-12 '>Job Type: {newjobcreationvalue.jobtype}</label>
          <label className='col-sm-12 '>Reason of Recruitment: {newjobcreationvalue.reasonrecruit}</label>
          <label className='col-sm-12 '>Preferred Working Location: {newjobcreationvalue.workinglocation==="Client"?newjobcreationvalue.workinglocationoth:newjobcreationvalue.workinglocation}</label>
          <label className='col-sm-12 '>Budget Band: Min  {newjobcreationvalue.budgetbandmin} Max  {newjobcreationvalue.budgetbandmax}</label>
          <label className='col-sm-12 '>Customer: {newjobcreationvalue.customer}</label>
          <label className='col-sm-12 '>Customer Name: {newjobcreationvalue.customersub}</label>
          <label className='col-sm-12 '>No. of Positions: {newjobcreationvalue.noofpositions}</label> */}
                  {/* <input className="col-sm-8 form-control" placeholder='Add status' onChange={(e)=>setaddnewobject({...addnewobject,status:e.target.value})}/> */}

                </span>  </div>



            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
                onClick={() => setpreviewscreen(false)}
              >
                Continue Editing
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {
                  handleadd()


                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModalLabel">
                View Job Posting
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal2"
              
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-4">


              <div className='my-2'>
                Details below:
                <span className='row p-3'>

                <table className="table table-sm table-striped ">
      
      <tbody>
        <tr>
          <th scope="row">Designation</th>
          <td>{viewobject.designation}</td>
        </tr>
        <tr>
          <th scope="row">Job Title</th>
          <td>{viewobject.job_position}</td>
        </tr>

        <tr>
          <th scope="row">Mandatory Skills</th>
          <td>{viewobject.mandatory_skillset}</td>
        </tr>
        <tr>
          <th scope="row">Good to Have Skills</th>
          <td>{viewobject.mandatory_skillset}</td>
        </tr>
        <tr>
          <th scope="row">Soft Skills</th>
          <td>{viewobject.soft_skills}</td>
        </tr>
        <tr>
          <th scope="row">Summary</th>
          <td>{viewobject.summary}</td>
        </tr>
        <tr>
          <th scope="row">Experience(in years) </th>
          <td> {viewobject.relevant_experience_min}-{viewobject.relevant_experience_max}</td>
        </tr>
        <tr>
          <th scope="row">Notice Period (in days)</th>
          <td>{viewobject.notice_period_days}</td>
        </tr>
        <tr>
          <th scope="row">Job Type</th>
          <td>{viewobject.job_type}</td>
        </tr>
        <tr>
          <th scope="row">Reason of Recruitment</th>
          <td>{viewobject.reason_for_recruitment}</td>
        </tr>
        <tr>
          <th scope="row">Preferred Working Location</th>
          <td>
            {viewobject.preferred_working_location}
          </td>
        </tr>
        <tr>
          <th scope="row">Budget Band(in lacs)</th>
          <td>{viewobject.budget_min_range}-{viewobject.budget_max_range}</td>
        </tr>
        <tr>
          <th scope="row">Customer</th>
          <td>{viewobject.customer_type}</td>
        </tr>
        <tr>
          <th scope="row">Customer Name</th>
          <td>{viewobject.customer}</td>
        </tr>
        <tr>
          <th scope="row">No. of Positions</th>
          <td>{viewobject.no_of_positions}</td>
        </tr>
        <tr>
          <th scope="row">Panelist</th>
          <td>{viewobject?.panel_list}</td>
        </tr>
      </tbody>
    </table>
 
                </span>  </div>



            </div>
           
          </div>
        </div>
      </div>
      <div >
        {/* <img src={bgpage} className='mx-auto'/> */}
        <div className='container dashboard '  >
          <div className='p-4'>
            <div className='my-2 d-flex flex-row-reverse '>
              <button className='btn btn-primary  my-2' onClick={() => setnewjobcreationscreen(!newjobcreationscreen)}>{newjobcreationscreen ? "View already created jobs" : "Create a new Job"}</button>
            </div>
            <h4 className='orangetheme'> Job Postings</h4>
            {/* {!newjobcreationscreen ? <>     
  <h6>View, Update & Delete Job Postings</h6></>:  <h6>Create new Job postings</h6>} */}

            {newjobcreationscreen ?
              <div className='p-5 card'>
                <button className='btn btn-outline-danger btn-sm m-2 ' style={{ width: "75px" }} onClick={() => setnewjobcreationvalue(initialnewjobcreationvalue)}>Reset</button>

                <ol type="1" className='my-3'>

                  <li className='mb-3'>
                    <div>Job ID</div>
                    <input className="form-control my-2" disabled placeholder='Job ID' />
                  </li>
                  <li className='mb-3'>
                    <div>Designation<span className="text-danger">*</span></div>
                    <select className='my-2 form-select' value={newjobcreationvalue.designation} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, designation: e.target.value })}>
                      <option>Select</option>
                      {designationlist?.map((item) => {
                        return <option>{item}</option>
                      })}

                    </select>

                  </li>
                  {/* <li className='mb-3'>
    <div>Job Role</div>
    <select className='my-2 form-select' value={newjobcreationvalue.jobrole} onChange={(e)=>setnewjobcreationvalue({...newjobcreationvalue,jobrole:e.target.value})}>
    <option>Select</option>
     {jobrolelist?.map((item)=>{
      return <option>{item}</option>
     })}
   
    </select>

  </li> */}

                  <li className='mb-3'>
                    <div>Job Title<span className="text-danger">*</span></div>

                    <div className='row'>
                      <select className='my-2 form-select col-sm mx-2' value={newjobcreationvalue.jobposition} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, jobposition: e.target.value })}>
                        <option>Select</option>
                        {jobpositionlist?.map((item) => {
                          return <option>{item.job_title}</option>
                        })}
                        <option>Others</option>
                      </select>
                      {newjobcreationvalue.jobposition === "Others" &&
                        <input className="form-control my-2 col-sm mx-1" placeholder='New Job Title ' value={newjobcreationvalue.jobtitle} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, jobtitle: e.target.value })} />
                      }
                    </div>
                  </li>
                  <li className='my-3'>
                    Skills
                    <ol type="a">
                      <li className='my-3'>
                        <div>Mandatory Skillset<span className="text-danger">*</span></div>


                        <Select className=" my-2 " options={technicalskillsoptions} isSearchable isMulti onChange={(value) => { setnewjobcreationvalue({ ...newjobcreationvalue, mandatoryskills: value?.map((it) => it.value) }) }} value={newjobcreationvalue?.mandatoryskills?.length > 0 && newjobcreationvalue?.mandatoryskills?.map((it) => ({ label: it, value: it }))} />


                      </li>
                      <li className='my-3'>
                        <div>Good to Have Skillset<span className="text-danger">*</span></div>


                        <Select className=" my-2 " options={technicalskillsoptions} isSearchable isMulti onChange={(value) => { setnewjobcreationvalue({ ...newjobcreationvalue, goodtohaveskills: value?.map((it) => it.value) }) }} value={newjobcreationvalue?.goodtohaveskills?.length > 0 && newjobcreationvalue?.goodtohaveskills?.map((it) => ({ label: it, value: it }))} />


                      </li>
                      <li className='my-3'>
                        <div>Soft Skills<span className="text-danger">*</span></div>


                        <Select className=" my-2 " options={softskillsoptions} isSearchable isMulti onChange={(value) => { setnewjobcreationvalue({ ...newjobcreationvalue, softskills: value?.map((it) => it.value) }) }} value={newjobcreationvalue?.softskills?.length > 0 && newjobcreationvalue?.softskills?.map((it) => ({ label: it, value: it }))} />


                      </li>
                    </ol>
                  </li>
                  <li className='my-3'>
                    <div>Summary<span className="text-danger">*</span></div>
                    <textarea rows="4" type="text" className='my-2 form-control' value={newjobcreationvalue.summary} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, summary: e.target.value })} />
                  </li>
                  <li>
                    <div>Relevant Experience (in Years)<span className="text-danger">*</span></div>
                    <div className='row  my-2'>

                      <div className='col-sm text-start'>Min:</div>
                      <div className='col-sm text-start'>Max:</div>
                    </div>
                    <div className='d-flex justify-content-between my-auto'>
                      <input className="form-control mx-2" type="number" style={{ width: "100px" }} min={0} value={SliderValue} onChange={(e) => setSliderValue(e.target.value)}
                        max={15} />

                      <Slider className="mx-2 my-2" value={SliderValue}
                        min={0} step={1} onChange={onSliderChange}
                        max={15} />






                      <input className="form-control mx-2 " type="number" style={{ width: "100px" }} min={0} value={SliderValue2} onChange={(e) => setSliderValue2(e.target.value)}
                        max={15} />
                      <Slider className="mx-2 my-2" value={SliderValue2}
                        min={0}
                        max={15} onChange={onSliderChange2} />
                    </div>

                    {/* <input className='form-control mx-2' placeholder='0' type="number" min={0} default={0}  max={15}/>
 <input className='form-control mx-2' placeholder='15' type="number" min={0} default={0} max={15}/> */}


                  </li>
                  <li className='my-3'>
                    <div>Notice Period (in Days)<span className="text-danger">*</span></div>
                    <select className='form-select my-1' value={newjobcreationvalue.noticeperiod} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, noticeperiod: e.target.value })}>
                      <option>Select</option>
                      <option>Immediate</option>
                      <option>15</option>
                      <option>30</option>
                      <option>60</option>
                      <option>90</option>

                    </select>
                    {/* <input className='form-control my-2' placeholder='Days' type="number" min={0} max={90} value={newjobcreationvalue.noticeperiod} onChange={(e)=>setnewjobcreationvalue({...newjobcreationvalue,noticeperiod:e.target.value})}  /> */}
                  </li>
                  <li className='my-3'>
                    <div>Job Type<span className="text-danger">*</span></div>

                    <div>
                      <input type='radio' name="jobtype" className='mx-1' value="Full Time" onChange={(e) => { console.log(e.target.value); setnewjobcreationvalue({ ...newjobcreationvalue, jobtype: e.target.value }) }} />
                      <label>Full Time<span className="text-danger">*</span></label>

                    </div>
                    <div> <input type='radio' name="jobtype" className='mx-1' value="Contractual" onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, jobtype: e.target.value })} /><label>Contractual</label></div>

                    {/* <input className='form-control my-2' placeholder='Days' type="number" min={0} max={90} value={newjobcreationvalue.noticeperiod} onChange={(e)=>setnewjobcreationvalue({...newjobcreationvalue,noticeperiod:e.target.value})}  /> */}
                  </li>
                  <li className='my-3'>
                    <div>Reason of recruitment?<span className="text-danger">*</span></div>
                    <div>
                      <input type='radio' name="reasonrecruitment" className='mx-1' value="Back Filled" onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, reasonrecruit: e.target.value })} />
                      <label>Back Filled</label>

                    </div>
                    <div> <input type='radio' name="reasonrecruitment" className='mx-1' value="Fresh" onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, reasonrecruit: e.target.value })} /><label>Fresh</label></div>

                    {/* <input className='form-control my-2' placeholder='Days' type="number" min={0} max={90} value={newjobcreationvalue.noticeperiod} onChange={(e)=>setnewjobcreationvalue({...newjobcreationvalue,noticeperiod:e.target.value})}  /> */}
                  </li>

                  <li className='my-3'>
                    <div>Preferred Working Location<span className="text-danger">*</span></div>
                    <select className='form-select my-2' value={newjobcreationvalue.workinglocation} onChange={(e) => { e.target.value !== "Select" && setnewjobcreationvalue({ ...newjobcreationvalue, workinglocation: e.target.value }) }}>
                      <option>
                        Select</option>
                      <option>Bangalore</option>
                      <option>Lucknow</option>
                      <option>Client</option>
                    </select>
                  </li>
                  {newjobcreationvalue.workinglocation === "Client" && <input className='form-control' placeholder="Client City" onChange={(e) => {
                    if (newjobcreationvalue.workinglocation === "Client") { setnewjobcreationvalue({ ...newjobcreationvalue, workinglocationoth: e.target.value }) }
                  }} />}

                  <li className='my-3'><div>Budget Band (in lacs)<span className="text-danger">*</span></div>
                    <span className='d-flex justify-content-between my-2'>
                      <input className='form-control mx-2' placeholder='Min Budget Range' type="number" min={0} value={newjobcreationvalue.budgetbandmin} onChange={(e) => { setnewjobcreationvalue({ ...newjobcreationvalue, budgetbandmin: e.target.value }) }} />
                      <input className='form-control mx-2' placeholder='Max Budget Range' type="number" min={0} value={newjobcreationvalue.budgetbandmax} onChange={(e) => { setnewjobcreationvalue({ ...newjobcreationvalue, budgetbandmax: e.target.value }) }} />

                    </span>

                  </li>
                  <li className='mb-3'>
                    <div>Customer<span className="text-danger">*</span></div>
                    <select className='form-select my-2' placeholder='New Job Title ' value={newjobcreationvalue.customer} onChange={(e) => { setnewjobcreationvalue({ ...newjobcreationvalue, customer: e.target.value, customersub: "" }); }}>
                      <option>Select</option>
                      <option>Client</option>
                      <option>Internal</option>
                      <option>Bench</option>
                    </select>
                    {newjobcreationvalue.customer === "Client" && <div>
                      <select className='form-select my-2' value={newjobcreationvalue.customersub} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, customersub: e.target.value })}>
                        <option>Select</option>
                        {clientlist?.map((item) => {
                          return <option>{item.client_name}</option>
                        })}
                      </select>
                    </div>}
                    {newjobcreationvalue.customer === "Bench" &&
                      <select className="form-select my-2" value={newjobcreationvalue.customersub} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, customersub: e.target.value })}>
                        <option>Select</option>
                        {verticallist?.map((item) => {
                          return <option>{item}</option>
                        })}
                      </select>
                    }
                    {newjobcreationvalue.customer === "Internal" &&
                      <select className="form-select my-2" value={newjobcreationvalue.customersub} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, customersub: e.target.value })}>
                        <option>Select</option>
                        {verticallist?.map((item) => {
                          return <option>{item}</option>
                        })}
                      </select>
                    }

                  </li>
                  <li className='mb-3'>
                    <div>No of Positions<span className="text-danger">*</span></div>
                    <input className='form-select my-2' placeholder='No of vacancies' value={newjobcreationvalue.noofpositions} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, noofpositions: e.target.value })} />


                  </li>
                  <li className='my-3'>
                    <div>Panelist<span className="text-danger">*</span></div>


                    <Select className=" my-2 " options={employeelist} isSearchable isMulti onChange={(value) => { setnewjobcreationvalue({ ...newjobcreationvalue, panelist: value?.map((it) => it.value) }) }} value={newjobcreationvalue?.panelist?.length > 0 && newjobcreationvalue?.panelist?.map((it) => ({ label: it, value: it }))} />


                  </li>
                </ol>
                <button className='btn btn-primary float-end' data-toggle={previewscreen ? "modal" : ""}
                  data-target="#exampleModal" onClick={() => { handlepreviewscreen() }}
                >
                  Preview</button>
              </div> :

              jobpostinglist?.length > 0 ?
                <div>
                 <div className=' px-4 py-3 my-4 ' 
                  // style={{border:"2px solid grey"}}
                  >
                    <div className='d-flex justify-content-between  '>
                      <div>Filter By <i className='fa fa-filter'></i>
                      </div>


                      <div>
                        Status:
                        <select className='mx-2 text-secondary rounded' onChange={(e) => { handlefiltervariables("status", e.target.value) }}>
                          <option>Select</option>
                          <option value="open">Open
                          </option>
                          <option value="closed">Closed</option></select>
                      </div>
                      <div>
                        Created By:
                        <select className='mx-2 text-secondary rounded' onChange={(e) => { handlefiltervariables("created_by", e.target.value) }}>
                          <option>Select</option>
                          {Array.from(new Set(jobpostinglist?.map((item) => { return item.created_by }))).map((it) => {
                            return <option>{it}</option>
                          })
                          }

                        </select>
                      </div>
                      <div>
                        Date Range:
                        <input className="mx-2 text-secondary rounded" style={{ border: "1px solid grey" }} type="date" onChange={(e) => { handlefiltervariables("startdate", e.target.value) }} />
                        <input className="mx-2 text-secondary rounded" style={{ border: "1px solid grey" }} type="date" onChange={(e) => { handlefiltervariables("enddate", e.target.value) }} />
                      </div>
                      <div>
                        <button className='btn btn-sm' style={{backgroundColor:"#cfe2ff"}} onClick={() => handlefilteroperations()}>Apply Filters</button>
                      </div>
                    </div>
                  </div> 
                  <div>
                    {jobpostingfilteredlist?.length > 0 ?
                      jobpostingfilteredlist?.map((item) => {
                        return <>
                          <div
                            className="row border rounded shadow mx-5 my-3   "
                            style={{ height: "77px" }}
                          >
                            <div
                              className="col-sm-4 rounded p-2 text-center"
                              style={{ background: "#fafafa" }}


                            >
                              <div className='position-absolute bg-success rounded-lg text-white p-1 text-sm' style={{ top: "0px", left: "0px", fontSize: "12px" }}>
                                {`${capitalizefirstleter(item.status)}`}
                              </div>
                              {/* <div>Job Id:{item.job_id}</div> */}
                              <div className='mt-3'>{item.job_position}</div>
                              <div className="">

                              </div>
                            </div>
                            <div className='col-sm-6 mt-3 '>
                              <div className='row'>
                                <div className='col-sm m-auto'>
                                  <div>Date Created: {formatdate(item.date_created)}</div>
                                  <div >Date Closed: {item.date_closed!==null?formatdate(item.date_closed):""} </div>

                                </div>
                                <div className='col-sm'>
                                  <div> Created By: {item.created_by}</div>
                                  <div>  Status: {item.status}
                                  </div></div>
                              </div>

                            </div>
                            <div className='col-sm-2'>
                              <div className="mt-4 d-flex flex-row-reverse">

                                {/* <button className='btn btn-danger mx-1  btn-sm'>
                                  <i class="fa fa-trash"></i>

                                </button> */}
                                <button className='btn btn-primary mx-1 btn-sm'>
                                  <i class="fa fa-edit"></i>
                                </button>
                                <button className='btn btn-success mx-1 btn-sm '>

                                  <i class="fa fa-eye" onClick={()=>{setviewobject(item)}} data-toggle="modal"
                  data-target="#exampleModal2" 
                                  //data-toggle="collapse" data-target={`#collapseExample${item.job_id}`} aria-expanded="false" aria-controls={`#collapseExample${item.job_id}`}
                                  ></i>
                                </button>
                              </div>

                            </div>

                          </div>
                          {/* <div className=" collapse mx-5" id={`collapseExample${item.job_id}`}>
                            <table className="table table-sm table-striped ">
      
                              <tbody>
                                <tr>
                                  <th scope="row">Designation</th>
                                  <td>{item.designation}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Job Title</th>
                                  <td>{item.job_position}</td>
                                </tr>

                                <tr>
                                  <th scope="row">Mandatory Skills</th>
                                  <td>{item.mandatory_skillset}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Good to Have Skills</th>
                                  <td>{item.mandatory_skillset}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Soft Skills</th>
                                  <td>{item.soft_skills}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Summary</th>
                                  <td>{item.summary}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Experience(in years) </th>
                                  <td> {item.relevant_experience_min}-{item.relevant_experience_max}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Notice Period (in days)</th>
                                  <td>{item.notice_period_days}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Job Type</th>
                                  <td>{item.job_type}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Reason of Recruitment</th>
                                  <td>{item.reason_for_recruitment}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Preferred Working Location</th>
                                  <td>
                                    {item.preferred_working_location}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Budget Band(in lacs)</th>
                                  <td>{item.budget_min_range}-{item.budget_max_range}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Customer</th>
                                  <td>{item.customer_type}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Customer Name</th>
                                  <td>{item.customer}</td>
                                </tr>
                                <tr>
                                  <th scope="row">No. of Positions</th>
                                  <td>{item.no_of_positions}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Panelist</th>
                                  <td>{item?.panel_list}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div> */}
                          </>
                      }) : <div>There are no matching records</div>
                    }

                  </div>
                </div>
                : <div>There are no existing job postings</div>
            }

          </div>
        </div>
      </div>
      <FooterPages />
    </>
  )
}

export default Dashboard